.ql-snow .ql-picker.ql-font {
  width: 136px;
}
.ql-container {
  font-size: 18px;
  max-width: 100% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal !important;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 4em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 4em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 5em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 5em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 7em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 7em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 8em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 8em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 10em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 10em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 11em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 11em;
}
.ql-editor .ql-size-small {
  font-size: 14px;
}
.ql-editor .ql-size-large {
  font-size: 24px;
}
.ql-editor .ql-size-huge {
  font-size: 32px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 14px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  font-size: 24px;
}
.ql-snow .ql-tooltip input[type="text"] {
  font-size: 18px;
}

.ql-font-sans-serif {
  font-family: sans-serif;
}
.ql-font-monospace {
  font-family: monospace;
}
.ql-font-roboto {
  font-family: "Roboto", sans-serif;
}
.ql-font-times {
  font-family: "Times New Roman", serif;
}
.ql-font-arial {
  font-family: Arial, sans-serif;
}
.ql-font-georgia {
  font-family: Georgia, serif;
}
.ql-font-courier {
  font-family: "Courier New", monospace;
}
.ql-font-comic {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-verdana {
  font-family: Verdana, sans-serif;
}
.ql-font-tahoma {
  font-family: Tahoma, sans-serif;
}
.ql-font-impact {
  font-family: Impact, sans-serif;
}

/* Ensure dropdown items display their correct fonts */
.ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
  content: "Sans Serif";
  font-family: sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "Monospace";
  font-family: monospace;
}
.ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before {
  content: "Roboto";
  font-family: "Roboto", sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="times"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="times"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman", serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "Arial";
  font-family: Arial, sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  content: "Georgia";
  font-family: Georgia, serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="courier"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="courier"]::before {
  content: "Courier New";
  font-family: "Courier New", monospace;
}
.ql-picker.ql-font .ql-picker-label[data-value="comic"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="comic"]::before {
  content: "Comic Sans";
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
  content: "Verdana";
  font-family: Verdana, sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="tahoma"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before {
  content: "Tahoma";
  font-family: Tahoma, sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="impact"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="impact"]::before {
  content: "Impact";
  font-family: Impact, sans-serif;
}
